<template>
  <article>
    <h1>공지사항</h1>
    <section class="sub3">
      <div class="right">
        <select>
          <option value> 제목 </option>
          <option value> 작성자 </option>
          <option value> 내용 </option>
        </select>
        <input type="text" autofocus required placeholder="검색어를 입력해주세요"><i class="fas fa-mouse-pointer"></i>
      </div>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>제목</th>
            <th>작성자</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody id="noticeList"></tbody>
      </table>
      <div class="pagenb">
          <i class="fas fa-caret-left"></i>
          <p v-on:click="noticePage(1);">1</p>
          <i class="fas fa-caret-right"></i>
        </div>
    </section>
  </article>
</template>
<script>
let currentPage = 1;
export default{
  mounted(){
    getPagedNotice({
      page: currentPage
      , callback: "noticeListCallback"
    });
  },
  methods: {
    noticePage(page) {
      if(currentPage != page){
        currentPage = page;
        getPagedNotice({
          page: currentPage
          , callback: "noticeListCallback"
        });
      }
    }
  }
}
</script>
